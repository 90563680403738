define('frontend/models/deckgroup', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		name: _emberData.default.attr('string'),
		url: _emberData.default.attr('string'),

		cardset: _emberData.default.belongsTo(),
		decks: _emberData.default.hasMany(),

		sortedDecks: Ember.computed('decks.@each.cardclass', function () {
			return this.get('decks').sortBy('cardclass.id_int');
		})
	});
});