define('frontend/models/stat', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		total: _emberData.default.attr(),
		completion: _emberData.default.attr(),
		extrahs: _emberData.default.attr(),
		extra: _emberData.default.attr()
	});
});