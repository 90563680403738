define('frontend/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
		currentUser: Ember.inject.service(),

		beforeModel() {
			return this._loadCurrentUser();
		},

		sessionAuthenticated() {
			this._super(...arguments);
			this._loadCurrentUser();
		},

		_loadCurrentUser() {
			return this.get('currentUser').load().catch(() => this.get('session').invalidate());
		}
	});
});