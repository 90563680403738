define('frontend/controllers/user/wanted', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		groups: Ember.computed('model.wantedcards', function () {
			let card_id,
			    index,
			    group,
			    card_ids = [],
			    groups = [];
			this.get('model.wantedcards').forEach(wantedcard => {
				card_id = wantedcard.card.get('id');
				index = card_ids.indexOf(card_id);
				if (index > -1) {
					group = groups[index];
					group.total += wantedcard.number;
					group.wantedcards.push(wantedcard);
					if (group.max < wantedcard.number) group.max = wantedcard.number;
				} else {
					card_ids.push(card_id);
					groups.push({
						card: wantedcard.card,
						total: wantedcard.number,
						max: wantedcard.number,
						wantedcards: [wantedcard]
					});
				}
			});

			return groups.sort((a, b) => a.total < b.total);
		})
	});
});