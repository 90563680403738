define('frontend/routes/user/arenas/arena', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		currentUser: Ember.inject.service(),

		model(params) {
			const user = this.get('currentUser.user');
			return Ember.RSVP.hash({
				arena: this.store.findRecord('arena', params.arena_id, {
					include: 'cardclass,arenamatches,arenamatches.cardclass'
				}),
				cardclasses: this.store.query('cardclass', { filter: { collectible: true } }),
				cardsets: this.store.query('cardset', { filter: { collectible: true } }),
				user: user
			});
		}
	});
});