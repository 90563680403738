define('frontend/router', ['exports', 'frontend/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	const Router = Ember.Router.extend({
		location: _environment.default.locationType,
		rootURL: _environment.default.rootURL
	});

	Router.map(function () {
		this.route('login');

		this.route('user', function () {
			this.route('profile');
			this.route('collection');
			this.route('packs');
			this.route('deckgroups', function () {
				this.route('deckgroup', { path: '/:deckgroup_id' });
				this.route('classdecks');
			});
			this.route('decks', function () {
				this.route('deck', { path: '/:deck_id' });
				this.route('compare', { path: '/:deck_id1/:deck_id2' });
			});
			this.route('import');
			this.route('stats');
			this.route('wanted');
			this.route('arenas', function () {
				this.route('arena', { path: '/:arena_id' });
			});
		});
	});

	exports.default = Router;
});