define('frontend/routes/user/arenas/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		currentUser: Ember.inject.service(),

		model() {
			const user = this.get('currentUser.user');
			return Ember.RSVP.hash({
				arenas: this.store.query('arena', {
					filter: { user: user.id },
					include: 'cardclass,arenamatches,arenamatches.cardclass,arenarewards,arenarewards.cardset,arenarewards.card'
				}),
				cardclasses: this.store.query('cardclass', { filter: { collectible: true } }),
				user: user
			});
		}
	});
});