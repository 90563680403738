define('frontend/routes/user/stats', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		queryParams: {
			fullStats: { refreshModel: true },
			standard: { refreshModel: true }
		},

		currentUser: Ember.inject.service(),

		model(params) {
			const user = this.get('currentUser.user');
			return Ember.RSVP.hash({
				years: this.store.query('year', { filter: params.standard ? { standard: params.standard } : {}, include: 'cardsets' }),
				cardsets: this.store.query('cardset', { filter: { collectible: true } }),
				cardclasses: this.store.query('cardclass', { filter: { collectible: true } }),
				rarities: this.store.query('rarity', { filter: { collectible: true } }),
				stat: this.store.queryRecord('stat', Ember.assign(params, { user: user.id })),
				pitycounters: this.store.query('pitycounter', { filter: { user: user.id }, include: 'rarity,cardset', sort: 'rarity_id' }),
				packs: this.store.query('pack', { filter: { user: user.id } }),
				user: user
			});
		}
	});
});