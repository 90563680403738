define('frontend/models/deck', ['exports', 'ember-data', 'deckstrings'], function (exports, _emberData, _deckstrings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		name: _emberData.default.attr('string'),
		url: _emberData.default.attr('string'),
		deckstring: _emberData.default.attr('string'),
		comment: _emberData.default.attr('string'),

		cardclass: _emberData.default.belongsTo(),
		deckcards: _emberData.default.hasMany(),
		deckgroup: _emberData.default.belongsTo(),
		user: _emberData.default.belongsTo(),
		wanteddecks: _emberData.default.hasMany(),
		deckstats: _emberData.default.hasMany(),

		id_int: Ember.computed('id', function () {
			return parseInt(this.get('id'));
		}),

		dust: Ember.computed('deckcards.@each.dust', function () {
			const deckcards = this.get('deckcards');
			return deckcards.reduce((value, deckcard) => {
				return value += deckcard.get('dust');
			}, 0);
		}),

		size: Ember.computed('deckcards.@each.number', function () {
			const deckcards = this.get('deckcards');
			return deckcards.reduce((value, deckcard) => {
				return value += deckcard.get('number');
			}, 0);
		}),

		sortedDeckcards: Ember.computed('deckcards.@each.card', function () {
			return this.get('deckcards').sortBy('card.cost', 'card.name_fr');
		}),

		belongsname: Ember.computed('deckgroup.name', 'user.name', function () {
			if (this.get('deckgroup.name')) {
				return this.get('deckgroup.name');
			} else {
				return this.get('user.name');
			}
		}),

		fullname: Ember.computed('name', 'cardclass.name_fr', 'deckgroup.name', 'user.name', function () {
			return `${this.get('cardclass.name_fr')} : ${this.get('name')} (${this.get('belongsname')})`;
		}),

		exportString: Ember.computed('cardclass', 'deckcards.@each.{number,card}', function () {
			const heroes = [0, 0, 274, 31, 637, 671, 813, 930, 1066, 893, 7];
			const cardclass = this.get('cardclass');
			const hero = heroes[cardclass.get('id')];

			const deckcards = this.get('deckcards');
			const cards = [];
			deckcards.forEach(deckcard => {
				cards.push([parseInt(deckcard.card.get('id')), deckcard.number]);
			});

			const deckStructure = {
				format: 2, // 1 pour Libre
				heroes: [hero],
				cards: cards
			};
			return (0, _deckstrings.encode)(deckStructure);
		})
	});
});