define('frontend/models/type', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		name: _emberData.default.attr('string'),
		name_fr: _emberData.default.attr('string'),
		collectible: _emberData.default.attr('boolean'),

		id_int: Ember.computed('id', function () {
			return parseInt(this.get('id'));
		})
	});
});