define('frontend/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _dataAdapterMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
		authorize(xhr) {
			let { access_token } = this.get('session.data.authenticated');
			xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
		}
	});
});