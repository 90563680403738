define('frontend/controllers/user/packs', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		pity_rarities: Ember.computed('model.rarities', function () {
			return this.get('model.rarities').filter(rarity => rarity.name_fr === 'Epique' || rarity.name_fr === 'Légendaire');
		}),

		actions: {
			filterPityBySet(cardset, pitycounter) {
				return pitycounter.cardset.get('id') === cardset.id;
			},

			save() {
				this.get('model.packs').forEach(pack => {
					pack.save();
				});
				this.get('model.pitycounters').forEach(pitycounter => {
					pitycounter.save();
				});
			}
		}
	});
});