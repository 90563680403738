define('frontend/routes/user/import', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		currentUser: Ember.inject.service(),

		model() {
			const user = this.get('currentUser.user');
			return Ember.RSVP.hash({
				decks: this.store.findAll('deck', {
					include: 'cardclass,deckgroup,user',
					fields: { deck: 'name,deckstring', cardclass: 'name', deckgroup: 'name', user: 'name' }
				}),
				user: user,
				deckgroups: this.store.query('deckgroup', {
					sort: '-cardset_id,name'
				}),
				cardsets: this.store.query('cardset', { filter: { collectible: true } })
			});
		}
	});
});