define('frontend/models/arenamatch', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		won: _emberData.default.attr('boolean'),

		arena: _emberData.default.belongsTo(),
		cardclass: _emberData.default.belongsTo()
	});
});