define('frontend/helpers/reduce_number', ['exports', 'ember-composable-helpers/helpers/reduce'], function (exports, _reduce) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _reduce.default.extend({
		callbackDidChange: Ember.observer('callback', 'initialValue', function () {
			let callback = Ember.get(this, 'callback');
			let initialValue = Ember.get(this, 'initialValue');

			if (Ember.isEmpty(callback)) {
				Ember.defineProperty(this, 'content', []);
				return;
			}

			let cp = Ember.computed('array.{[],@each.number}', () => {
				let array = Ember.get(this, 'array');
				return array.reduce(callback, initialValue);
			});

			Ember.defineProperty(this, 'content', cp);
		})
	});
});