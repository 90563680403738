define('frontend/models/arenareward', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		gold: _emberData.default.attr('number'),
		dust: _emberData.default.attr('number'),
		golden: _emberData.default.attr('boolean'),

		arena: _emberData.default.belongsTo(),
		cardset: _emberData.default.belongsTo(),
		card: _emberData.default.belongsTo(),

		text: Ember.computed('gold', 'dust', 'cardset', 'card', 'golden', function () {
			const gold = this.get('gold');
			if (gold) return gold + " or";
			const dust = this.get('dust');
			if (dust) return dust + " poussière";
			const cardset = this.get('cardset');
			if (cardset) return "1 paquet " + cardset.get('name_fr');
			return "?";
		})
	});
});