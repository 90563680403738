define('frontend/controllers/login', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		session: Ember.inject.service(),
		currentUser: Ember.inject.service(),

		actions: {
			authenticate() {
				const { login, password } = this.getProperties('login', 'password');
				this.get('session').authenticate('authenticator:oauth2', login, password).then(() => {
					this.get('currentUser').load().then(() => {
						this.transitionToRoute('user.collection');
					});
				}).catch(reason => {
					this.set('errorMessage', reason.error || reason);
				});
			}
		}
	});
});