define('frontend/routes/user/wanted', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		currentUser: Ember.inject.service(),

		model() {
			const user = this.get('currentUser.user');
			return Ember.RSVP.hash({
				wantedcards: this.store.query('wantedcard', {
					filter: { user: user.id },
					include: 'card,card.cardset,card.deckcards,card.deckcards.deck,card.deckcards.deck.cardclass,card.deckcards.deck.deckgroup,' + 'wanteddeck,wanteddeck.deck,wanteddeck.deck.cardclass,wanteddeck.deck.deckgroup'
				}),
				user: user
			});
		}
	});
});