define('frontend/services/current-user', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		session: Ember.inject.service('session'),
		store: Ember.inject.service(),

		load() {
			if (this.get('session.isAuthenticated')) {
				return this.get('store').queryRecord('user', { me: true }).then(user => {
					this.set('user', user);
				});
			} else {
				return Ember.RSVP.resolve();
			}
		}
	});
});