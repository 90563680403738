define('frontend/routes/user/packs', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		currentUser: Ember.inject.service(),

		model() {
			const user = this.get('currentUser.user');
			return Ember.RSVP.hash({
				cardsets: this.store.query('cardset', { filter: { collectible: true } }),
				rarities: this.store.query('rarity', { filter: { collectible: true } }),
				pitycounters: this.store.query('pitycounter', { filter: { user: user.id }, include: 'rarity,cardset', sort: 'rarity_id' }),
				packs: this.store.query('pack', { filter: { user: user.id } }),
				user: user
			});
		}
	});
});