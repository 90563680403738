define('frontend/adapters/user', ['exports', 'frontend/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		urlForQueryRecord(query) {
			if (query.me) {
				delete query.me;
				return `current_user`;
			}

			return this._super(...arguments);
		}
	});
});