define('frontend/models/year', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		name: _emberData.default.attr('string'),
		name_fr: _emberData.default.attr('string'),
		standard: _emberData.default.attr('boolean'),

		cardsets: _emberData.default.hasMany(),

		size: Ember.computed('cardsets', function () {
			return this.get('cardsets').length;
		})
	});
});