define('frontend/models/card', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		hs_card_id: _emberData.default.attr('string'),
		name: _emberData.default.attr('string'),
		name_fr: _emberData.default.attr('string'),
		cardtext: _emberData.default.attr('string'),
		cardtext_fr: _emberData.default.attr('string'),
		cost: _emberData.default.attr('number'),
		health: _emberData.default.attr('number'),
		atk: _emberData.default.attr('number'),

		cardset: _emberData.default.belongsTo(),
		cardclass: _emberData.default.belongsTo(),
		rarity: _emberData.default.belongsTo(),
		type: _emberData.default.belongsTo(),
		collections: _emberData.default.hasMany(),
		wantedcards: _emberData.default.hasMany(),
		deckcards: _emberData.default.hasMany(),

		image: Ember.computed('hs_card_id', function () {
			const LOCALE = 'frFR';
			const RESOLUTION = '256x';
			const CARD_ID = this.get('hs_card_id');
			const EXT = 'png';
			return `https://art.hearthstonejson.com/v1/render/latest/${LOCALE}/${RESOLUTION}/${CARD_ID}.${EXT}`;
		}),

		creationDust: Ember.computed('rarity', function () {
			return this.get('rarity.creationDust');
		}),

		creationDustGolden: Ember.computed('rarity', function () {
			return this.get('rarity.creationDustGolden');
		}),

		destructionDust: Ember.computed('rarity', function () {
			return this.get('rarity.destructionDust');
		}),

		destructionDustGolden: Ember.computed('rarity', function () {
			return this.get('rarity.destructionDustGolden');
		})
	});
});