define('frontend/models/cardset', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		name: _emberData.default.attr('string'),
		name_fr: _emberData.default.attr('string'),
		collectible: _emberData.default.attr('boolean'),
		standard: _emberData.default.attr('boolean'),

		year: _emberData.default.belongsTo(),

		image: Ember.computed('id', function () {
			return `/assets/cardset_${this.get('id')}.png`;
		}),

		class: Ember.computed('id', 'year', function () {
			if (this.get('id') === '1130') {
				// Latest set
				return 'current';
			} else if (this.get('year.id') === '4') {
				// Latest year
				return 'year';
			} else if (this.get('id') === '3') {
				// Classic
				return 'Classique';
			} else if (this.get('id') === '2') {
				// Basic
				return 'Basique';
			} else {
				return '';
			}
		})
	});
});