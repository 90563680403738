define('frontend/models/deckstat', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		win: _emberData.default.attr('number'),
		loose: _emberData.default.attr('number'),
		wincasual: _emberData.default.attr('number'),
		loosecasual: _emberData.default.attr('number'),

		deck: _emberData.default.belongsTo(),
		user: _emberData.default.belongsTo()
	});
});