define('frontend/models/deckcard', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		number: _emberData.default.attr('number'),

		deck: _emberData.default.belongsTo(),
		card: _emberData.default.belongsTo(),

		dust: Ember.computed('card', 'number', function () {
			const card = this.get('card');
			const number = this.get('number');
			return number * card.get('creationDust');
		}),

		fulldeck: Ember.computed('deck', function () {
			return this.get('deck.fullname');
		})
	});
});