define('frontend/models/wantedcard', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		number: _emberData.default.attr('number'),

		user: _emberData.default.belongsTo(),
		card: _emberData.default.belongsTo(),
		wanteddeck: _emberData.default.belongsTo(),

		fulldeck: Ember.computed('wanteddeck', function () {
			const wanteddeck = this.get('wanteddeck');
			return wanteddeck.get('id') ? wanteddeck.get('deck.fullname') : "(hors decks)";
		})
	});
});