define('frontend/controllers/user/arenas/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		class: 2, // Druid

		actions: {
			save() {
				this.get('store').createRecord('arena', {
					date: new Date(),
					archetype: this.get('archetype'),
					score: this.get('score'),
					done: false,
					win: 0,
					cardclass: this.get('store').peekRecord('cardclass', this.get('class')),
					user: this.get('model.user')
				}).save().then(arena => {
					this.transitionToRoute('user.arenas.arena', arena.id);
				});
			}
		}
	});
});