define('frontend/models/arena', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		date: _emberData.default.attr('date'),
		archetype: _emberData.default.attr('string'),
		score: _emberData.default.attr('number'),
		done: _emberData.default.attr('boolean'),
		win: _emberData.default.attr('number'),

		cardclass: _emberData.default.belongsTo(),
		user: _emberData.default.belongsTo(),
		arenamatches: _emberData.default.hasMany(),
		arenarewards: _emberData.default.hasMany(),

		gain: Ember.computed('arenarewards', function () {
			const arenarewards = this.get('arenarewards').toArray();
			// Remove pack that could have been bought
			const packIndex = arenarewards.indexOf(arenarewards.find(arenareward => arenareward.cardset.get('id')));
			arenarewards.splice(packIndex, 1);
			const packs = arenarewards.filter(arenareward => arenareward.cardset.get('id'));
			const cards = arenarewards.filter(arenareward => arenareward.card.get('id'));
			const dust = arenarewards.reduce((dust, arenareward) => {
				return dust + arenareward.dust;
			}, 0);
			const gold = arenarewards.reduce((gold, arenareward) => {
				return gold + arenareward.gold;
			}, -50); // Remove arena fee and add pack cost

			let gains = [];
			if (packs.length) gains.push(packs.firstObject.text);
			if (cards.length) gains.push(cards.firstObject.text);
			if (dust) gains.push(dust + " poussière");
			gains.push(gold + " or");
			return gains.join(' + ');
		})
	});
});