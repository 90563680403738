define('frontend/models/rarity', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		name: _emberData.default.attr('string'),
		name_fr: _emberData.default.attr('string'),

		image: Ember.computed('id', function () {
			return `/assets/rarity_${this.get('id')}.png`;
		}),

		creationDust: Ember.computed('name_fr', function () {
			switch (this.get('name_fr')) {
				case 'Commune':
					return 40;
				case 'Rare':
					return 100;
				case 'Epique':
					return 400;
				case 'Légendaire':
					return 1600;
				default:
					return 0;
			}
		}),

		creationDustGolden: Ember.computed('name_fr', function () {
			switch (this.get('name_fr')) {
				case 'Commune':
					return 400;
				case 'Rare':
					return 800;
				case 'Epique':
					return 1600;
				case 'Légendaire':
					return 3200;
				default:
					return 0;
			}
		}),

		destructionDust: Ember.computed('name_fr', function () {
			switch (this.get('name_fr')) {
				case 'Commune':
					return 5;
				case 'Rare':
					return 20;
				case 'Epique':
					return 100;
				case 'Légendaire':
					return 400;
				default:
					return 0;
			}
		}),

		destructionDustGolden: Ember.computed('name_fr', function () {
			switch (this.get('name_fr')) {
				case 'Commune':
					return 50;
				case 'Rare':
					return 100;
				case 'Epique':
					return 400;
				case 'Légendaire':
					return 1600;
				default:
					return 0;
			}
		})
	});
});