define('frontend/models/wanteddeck', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		user: _emberData.default.belongsTo(),
		deck: _emberData.default.belongsTo(),
		wantedcards: _emberData.default.hasMany()
	});
});