define('frontend/controllers/user/profile', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		session: Ember.inject.service(),

		actions: {
			saveName() {
				this.get('model').save();
			},

			invalidateSession() {
				this.get('session').invalidate();
			}
		}
	});
});