define('frontend/routes/user/deckgroups/deckgroup', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		currentUser: Ember.inject.service(),

		model(params) {
			const user = this.get('currentUser.user');
			return Ember.RSVP.hash({
				deckgroup: this.store.findRecord('deckgroup', params.deckgroup_id, {
					include: 'cardset,decks,decks.cardclass,decks.deckcards,decks.deckcards.card,decks.deckcards.card.rarity,decks.deckcards.card.collections,decks.deckcards.card.collections.user,decks.wanteddecks.user',
					fields: { cardsets: 'name_fr', decks: 'name,url,cardclass,deckcards,wanteddecks', deckcards: 'number,card', collections: 'number,user' }
				}),
				cardclasses: this.store.query('cardclass', { filter: { collectible: true } }),
				user: user
			});
		}
	});
});