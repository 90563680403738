define('frontend/routes/user/profile', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		currentUser: Ember.inject.service(),

		model() {
			return this.get('currentUser.user');
		}
	});
});