define('frontend/routes/user/decks/compare', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		currentUser: Ember.inject.service(),

		model(params) {
			const user = this.get('currentUser.user');
			return Ember.RSVP.hash({
				deck1: this.store.findRecord('deck', params.deck_id1, {
					reload: true,
					include: 'user,deckgroup,cardclass,deckcards,deckcards.card,deckcards.card.rarity,deckcards.card.cardset,deckcards.card.cardset.year,deckcards.card.cardclass,deckcards.card.type,deckcards.card.collections,deckcards.card.collections.user,wanteddecks.user,deckstats,deckstats.user',
					fields: { users: 'name', cardsets: 'name_fr,year', deckcards: 'number,card', cards: 'cost,atk,health', collections: 'number,user', deckstats: 'win,loose,wincasual,loosecasual' }
				}),
				deck2: this.store.findRecord('deck', params.deck_id2, {
					reload: true,
					include: 'user,deckgroup,cardclass,deckcards,deckcards.card,deckcards.card.rarity,deckcards.card.cardset,deckcards.card.cardset.year,deckcards.card.cardclass,deckcards.card.type,deckcards.card.collections,deckcards.card.collections.user,wanteddecks.user,deckstats,deckstats.user',
					fields: { users: 'name', cardsets: 'name_fr,year', deckcards: 'number,card', cards: 'cost,atk,health', collections: 'number,user', deckstats: 'win,loose,wincasual,loosecasual' }
				}),
				user: user
			});
		}
	});
});