define('frontend/routes/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		/*model() {
  	return this.store.findAll('user');
  },
  	setupController(controller, model) {
  	this._super(controller, model);
  	controller.set('initCollection', 'empty');
  }*/
	});
});